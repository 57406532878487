.loading {
  background: #FE8F12;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  color: #111;
}
.loader {
  margin-top: 40vh;
  text-align: center;
  font-weight: 700;
  font-size: 30px;
}
.loader .ant-spin-dot-item {
  background-color: #111;
}
.loader .ant-spin-text {
  color: #111;
}
.mapboxgl-ctrl-bottom-left {
  margin-left: 280px;
}
.mapboxgl-ctrl-attrib {
  right: 290px;
  bottom: 5px;
  position: fixed;
}
.filters {
  width: 280px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  background: #fff;
  box-shadow: 0 0 16px 4px rgba(20,33,61,.2);
  overflow-x: visible;
  overflow-y: scroll;
}
button {
  font-family: "Questrial", sans-serif;
}
@media(max-width: 700px) {
  .filters {
    left: 0;
    width: 150px;
    top: 0;
    height: 100vh;
  }
}
.logo {
  width: 20px;
  display: block;
  height: 20px;
  background: url('./pi-logo.png') no-repeat;
  background-size: contain;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 20px;
  display: none;
}
@media(max-width: 700px) {
  .filters .header {
    top: 0;
    width: 110px;
  }
}
.filter-header button {
  width: 100%;
  display: block;
  border: none;
  background: #FE8F12;
  padding: 15px 20px;
  color: #000;
  text-align: left;
  font-size: 14px;
  outline: none;
  font-weight: bold;
  cursor: pointer;
}
.filter-content {
  padding: 10px 20px;
}
.filters label {
  text-transform: uppercase;
  font-size: 14px;
  display: block;
  margin: 0 0 3px 0;
  color: #999;
}
.filters .regions {
  color: #777;
}
.filters select {
  width: 100%;
  color: #444;
  background: #eee;
  font-size: 12px;
  border: none;
  height: 30px;
}
.filters .orgs {
  color: #999;
}
.filters .conflict {
  color: #999;
}


.mask {
  width: 100%;
  height: 100%;
  background: rgba(255,255,255, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  display: none;
}
.mask .text {
  color: #111;
  font-size: 30px;
  text-align: right;
  position: fixed;
  width: 80%;
  top: 20%;
  padding: 0 0 10px 0;
  left: 40px;
  border-bottom: 2px solid #333;
}


.pi-menu {
  margin-bottom: 15px;
}
.pi-menu-content {
  overflow-y: scroll;
  max-height: 900px;
}
.pi-menu button {
  display: block;
  width: 100%;
  border: none;
  font-size: 12px;
  background: #eee;
  color: #444;
  padding: 10px;
  margin-top: 1px;
  cursor: pointer;
  outline: none;
}
.pi-menu button:hover {
  background: #f9f9f9;
}
.pi-menu button.active {
  background: #FE8F12;
  color: #fff;
}
.conflict .pi-menu button.active {
  background: #ED1B23;
  color: #fff;
}
.actors .pi-menu button.active {
  background: #ED1B23;
  color: #fff;
}
.pi-menu-header {
  font-size: 12px;
  padding: 3px 0;
  color: #777;
}



.conflict-details {
  position: fixed;
  height: 100vh;
  overflow-y: scroll;
  background: rgba(255,255,255,1);
  border-top: 1px solid #ddd;
  width: 280px;
  left: 280px;
  top: 0;
  z-index: 80;
  color: #ccc;
  font-size: 12px;
  padding: 0 20px 0 20px;
  box-shadow: 0 0 16px 4px rgba(20,33,61,.2);
  text-align: right;
  animation: fadein 0.6s;
}
.conflict-details .item {
  line-height: 1.3;
  font-size: 12px;
  padding: 10px 20px 10px 0;
  border-top: 3px solid #ED1B23;
  text-align: left;
  margin: 0 0 20px 0;
  overflow: hidden;
  color: #111;
}
.conflict-details .btn-close {
  background: none;
  color: #777;
  border: none;
  padding: 7px 0;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}
.org-details {
  position: fixed;
  height: 100vh;
  background: rgba(255,255,255,1);
  border-top: 1px solid #ddd;
  width: 280px;
  overflow-y: scroll;
  left: 280px;
  z-index: 90;
  top: 0;
  color: #111;
  font-size: 14px;
  padding: 0 20px 0 20px;
  box-shadow: 0 0 16px 4px rgba(20,33,61,.2);
  text-align: left;
  animation: fadein 0.3s;
}
.org-details .title {
  margin: 20px 0 20px 0;
  font-size: 16px;
}
.org-details .item {
  line-height: 1.3;
  font-size: 12px;
  padding: 10px 20px 10px 0;
  border-top: 3px solid #FE8F12;
  text-align: left;
  margin: 0 0 20px 0;
  overflow: hidden;
  color: #111;
}
.org-details .item a {
  color: #FE8F12;
  text-decoration: none;
}
.org-details .item a:hover {
  opacity: 0.5;
}
.org-details .btn-close {
  background: none;
  color: #777;
  right: 20px;
  top: 5px;
  position: absolute;
  border: none;
  padding: 7px 0;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}
.organization-chart {
  background: #f9f9f9;
  height: 300px;
  margin: 0 0 40px 0;
  font-size: 12px;
  color: #777;
}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to   { opacity: 0; }
}

.conflict-switch,
.org-switch {
  font-size: 13px;
  margin: 0 0 20px 0;
  color: #444;
}
.conflict-switch .ant-switch {
  background-color: #ED1B23;
}
.org-switch .ant-switch {
  background-color: #FE8F12;
}

.conflict-header button {
  background: #ED1B23;
  color: #fff;
}
.axis-controls {
  margin: 10px 0;
  text-align: right;
}
.axis-button {
  border: none;
  background: none;
  cursor: pointer
}
