.pi-map {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.zoom-cntrl {
  right: 40px;
  top: 40px;
  position: fixed;
}
