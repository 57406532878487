.profile {
  position: fixed;
  right: 0;
  top: 0;
  background: #fff;
  overflow-y: scroll;
  height: 100%;
  width: 280px;
  box-shadow: 0 0 16px 4px rgba(20,33,61,.2);
}
.profile h1 {
  font-size: 16px;
}
.profile h2 {
  color: #111;
  font-size: 14px;
}
.country {
  font-weight: bold;
  color: #111;
  padding: 20px 20px 0px 20px;
}
.organization-attributes {
  margin: 0 0 20px 0;
}
.incident-count {
  padding: 10px 20px;
  font-size: 12px;
}
.fatalities-count {
  padding: 10px 20px;
  font-size: 12px;
}
.pi-chart {
  height: 50px;
  background: #FFF0F0;
  margin: 10px 20px 0 20px;
}
.actors-count {
  padding: 20px;
  font-size: 12px;
  color: #555;
  max-height: 588px;
  overflow: hidden;
}
.actors-count .actor {
  background: #f9f9f9;
  padding: 12px 10px 10px 10px;
  position: relative;
  margin-bottom: 5px;
}
.percent-bar {
  background: #FFF0F0;
  position: absolute;
  left: 0;
  top: 0;
  height: 3px;
  width: 100%;
}
.bar {
  background: #ED1B23;
  position: absolute;
  left: 0;
  top: 0;
  height: 3px;
  min-width: 3px;
}
.international-orgs {
  padding: 20px;
  height: 300px;
}
